import React from 'react'
import "./Thankyou.scss";
import Image from '../Assets/Images/logo.png';
import ThankYou from './thankyou.png';
import { Link } from 'react-router-dom';


const Thankyou = () => {
  return (
    <div className='thankyou-wrap'>
      <div className='Container'>
      <div className='brandneed-logo'>
      <Link to="/">
        <img className='logo-pic' src={Image} alt="" />
          <span className='logo-text'>The Brandneed</span>
          </Link>
        </div>
        <div className='thankyou-content'>
        {/* <img src={ThankYou} alt=""/> */}
        <p>We appreciate you contacting us.</p>
        <p>We will respond to your query as soon as possible.</p>
        <div className='goback'>
        <Link className="" to="/">Go to Homepage</Link>
        </div>
        </div>
      </div>
      </div>
  )
}

export default Thankyou