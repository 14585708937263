import React, { useEffect, useRef } from 'react'
import "./Header.scss";
import Image from '../Assets/Images/logo.png';
import Image1 from '../Assets/Images/phone1.png';
import Image2 from '../Assets/Images/mail.png'
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { useState } from 'react';


const Header = () => {
  const [openDrop, setOpenDrop] = useState(false);
  const handleOpen = () => {
    setOpenDrop(prev => !prev)
  }


  const aboutUsScroll = secRef => {
    document.dispatchEvent(new Event("aboutus_Selection"))
  }
  const serviceScroll = secRef => {
    document.dispatchEvent(new Event("service_Selection"))
  }
  const testimonialScroll = secRef => {
    document.dispatchEvent(new Event("testimonial_Selection"))
  }
  const contactScroll = secRef => {
    document.dispatchEvent(new Event("contact_Selection"))
  }

  const aboutUsClick = () => {
    aboutUsScroll()
  }
  const serviceClick = () => {
    serviceScroll()
  }
  const testimonialClick = () => {
    testimonialScroll()
  }
  const contactClick = () => {
    contactScroll()
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  // const navigate = useNavigate()
  // const goToBlogs = ()=> navigate(`blog/`)

  return (
    <React.Fragment>
      <div className="head-top-wrap">
        <div className='Container upper-header'>
          <div className='upper-header-left-container'>
            <p className='digital-markerting-agency'>Looking for a Digital Marketing Agency ?</p>
            <span className='call-us-link' onClick={contactClick}> Get in touch</span>
          </div>
          <div className='upper-header-right-container'>
            <div className='contact-no-conatiner'>
              <i className='contact-logo'><img src={Image1} alt="" /></i>
              <a href="tel:+918368214889" target="_blank" rel="noreferrer" className='brandneed-contact-no'>+91-8368214889</a>
            </div>

            <div className='mail-contact-conatiner'>
              <i className='mail-icon'><img src={Image2} alt="" /></i>
              <a href="mailto:support@edneed.com" target="_blank" rel="noreferrer" className='brandneed-mail'>support@edneed.com</a>
            </div>
          </div>
        </div>
      </div>
      <header>
        <div className='brandneed-header'>
          <div className='Container'>
            <NavLink className='brandneed-logo' to="/">
              <img className='logo-pic' src={Image} alt="" />
              <span className='logo-text'>The Brandneed</span>
            </NavLink>
            <button className='navbar-toggler' onClick={handleOpen} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className='navbar-toggler-icon'></span>
              <span className='navbar-toggler-icon'></span>
              <span className='navbar-toggler-icon'></span>

            </button>
            <div className={`header-component-hide ${openDrop ? "header-component-show" : ""}`}   >
              <ul>
                {/* <li className='co-header-component' id='co-header-first-component' onClick={scrollTop}>Home</li>
                <li className='co-header-component' onClick={aboutUsClick}>About us</li>
                <li className='co-header-component' onClick={serviceClick}>Service</li>
                <li className='co-header-component' onClick={testimonialClick}>Testimonials</li>
                <li className='co-header-component' onClick={contactClick}>Contact</li> */}

                <li className='co-header-component' id='co-header-first-component'><NavLink to="/">Home</NavLink></li>
                <li className='co-header-component'><NavLink to="/about">About us</NavLink></li>
                <li className='co-header-component'><NavLink to="/service">Service</NavLink></li>
                <li className='co-header-component'><NavLink to="/testimonial">Testimonials</NavLink></li>
                <li className='co-header-component'><a href='https://thebrandneed.com/blog/' rel="noreferrer" target="_blank" >Blogs</a></li>
              
                <li className='co-header-component'><NavLink to="/contact">Contact</NavLink></li>
              </ul>

            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header