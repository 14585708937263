import React from "react";
import Layout from "../Layout";
import BrandneedContent from "./Brandneed"
const BrandneedHomePage = () => {
  return (
    <React.Fragment>
<Layout>
  <BrandneedContent />
</Layout>
    </React.Fragment>
  )
}
export default BrandneedHomePage