import React from 'react'
import Layout from '../../Layout'
import Image35 from '../../Assets/Images/about.png'
import Image40 from '../../Assets/Images/management.png'
import Image41 from '../../Assets/Images/handshake.png'
import Image42 from '../../Assets/Images/justice-scale.png'
import Image43 from '../../Assets/Images/customer-service.png'
import Mahima from '../../Assets/Images/mahima.svg'
import Sammy from '../../Assets/Images/sammy.svg'
import Raj from '../../Assets/Images/raj.svg'
import Yash from '../../Assets/Images/yash.svg'

import "./About.scss";

const About = () => {
  return (
    <Layout>
   <div className='Container brandneed-container about-page'>
          <div className='about-page-container'>
            <div className='about-us-conatiner-first'>
              <p className='about-us-heading'>About us </p>
              <p className='about-us-subheading'>Take your Brand to Extraordinary Heights</p>
            </div>
            <div className='about-us-conatiner-second'>
              <div className='about-image-container'><img className='about-us-image' src={Image35} alt="" /></div>
              <div className='about-us-main-content-contaiiner' >
                <p className='about-us-content' >The Brandneed is the ultimate destination for all the requirements that your Brand needs.
                  We, at Brandneed, offer unmatched Digital Marketing Solutions that can instantly boost your brand’s reputation and skyrocket its growth. The need for Digital Marketing in today’s world is inevitable, the competition is tough and the need to shine out from the rest is at an all-time high.
                  The Brandneed is the ideal platform for you, that provides multiple online marketing strategies which can uplift your brand’s value with maximum cost-effectiveness.
                </p>
                <p className='about-us-content'>To know more about us :- Check our website <a href="https://thebrandneed.com/">thebrandneed.com</a>  </p>
              </div>
            </div>
          </div>

          <div className='about-us-container'>
            <div className='about-us-conatiner-first'>
              <p className='about-us-heading'>Our Team </p>
              <p className='about-us-subheading'>Meet our people</p>
            </div>
            <div className='about-us-container2-second'>
              <div className="about-us-card-second">
                <div className="about-image-container2">
                  <img className='about-us-image1' src={Raj} alt="" />
                </div>
                  <p className='about-us-name'>Raj Singh</p>
                  <p className='about-us-tittle'>Partner/Board of Director</p>
              </div>
              <div className="about-us-card-second">
                <div className="about-image-container2">
                  <img className='about-us-image1' src={Mahima} alt="" />
                </div>
                  <p className='about-us-name'>Mahima Anand</p>
                  <p className='about-us-tittle'>Partner/Business Head</p>
              </div>
              <div className="about-us-card-second">
                <div className="about-image-container2">
                  <img className='about-us-image1' src={Sammy} alt="" />
                </div>
                  <p className='about-us-name'>Saranjeet Singh</p>
                  <p className='about-us-tittle'>CEO/Founder</p>
              </div>
              <div className="about-us-card-second">
                <div className="about-image-container2">
                  <img className='about-us-image1' src={Yash} alt="" />
                </div>
                  <p className='about-us-name'>Yash Sharma</p>
                  <p className='about-us-tittle'>Sales Specialist</p>
              </div>
            </div>
          </div>

          <div className='about-us-container'>
            <div className='about-us-conatiner-first'>
              <p className='about-us-heading'>Our Values </p>
              <p className='about-us-subheading'>Values we adhere to</p>
            </div>

            <div className='about-us-container3-second'>
              <div className="about-image-container3">
                <img className='about-us-image' src={Image40} alt="" />
              </div>
              <div className="about-us-main-content-container3">
                <div className="content-first">
                  <div className="about-us-logo">
                    <img className='about-us-image1' src={Image41} alt="" />
                  </div>
                  <div className="about-us-content-text">
                    <p className='heading-blue' >Integrity</p>
                    <p className='about-us-content-container3'>Honesty is the best policy. At Edneed, we believe that one should observe honesty with their work, peers, society but most importantly themselves. Because it is us who are most affected by our actions.</p>
                  </div>
                </div>
                <div className="content-first">
                  <div className="about-us-logo">
                    <img className='about-us-image1' src={Image42} alt="" />
                  </div>
                  <div className="about-us-content-text">
                    <p className='heading-blue' >Equality</p>
                    <p className='about-us-content-container3'>We believe that the greatest privilege in life is to have access to an exemplary mentor. Edneed is our step towards making the world a level plain field, as quality education is fundamental to development.</p>
                  </div>
                </div>
                <div className="content-first">
                  <div className="about-us-logo">
                    <img className='about-us-image1' src={Image43} alt="" />
                  </div>
                  <div className="about-us-content-text">
                    <p className='heading-blue' >Selfless service</p>
                    <p className='about-us-content-container3'>We are dedicated to giving back to our society through the medium of education. By making knowledge accessible, finding opportunities easier and empowering educational institutes with tools that will take them to the next level.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </Layout>
  )
}

export default About