import React from 'react'
import './PageNotFound.scss'
import ErrorImg from '../../Assets/Images/Error404(1).png'
import { Link } from "react-router-dom";
import Layout from '../../Layout';
const PageNotFound = () => {
  return (
    <Layout>
      <div className="page-not-found">
        <div className="error-img-wrap ">
          <img src={ErrorImg} alt="" className="error-img " />
        </div>
        <h1 className="heading">We lost this page</h1>
        <p className="desc">We searched high and low but couldn’t find what
          you’re looking for</p>
        <button className="go-home-btn"> <Link to='/' >Go Home</Link> </button>
      </div>
    </Layout>
  )
}

export default PageNotFound
