import React from 'react'
import "./Footer.scss"
import LogoFooter from '../Assets/Images/logo-white.png';
import Image2 from '../Assets/Images/phone.png';
import Image3 from '../Assets/Images/email.png';
import Image4 from '../Assets/Images/location.png';
import Facebook from "../Assets/Images/social/facebook-white.png";
import Linkedin from "../Assets/Images/social/linkedin-white.png";
import Twitter from "../Assets/Images/social/twitter-white.png";
import Youtube from "../Assets/Images/social/youtube-white.png";
import Instagram from "../Assets/Images/social/instagram-white.png";
import { Link } from 'react-router-dom';

const Footer = () => {

  
  const seoScroll = secRef => {
    document.dispatchEvent(new Event("seo_Selection"))
  }
  const ppcScroll = secRef => {
    document.dispatchEvent(new Event("ppc_Selection"))
  }
  const wbScroll = secRef => {
    document.dispatchEvent(new Event("wb_Selection"))
  }
  const logoScroll = secRef => {
    document.dispatchEvent(new Event("logo_Selection"))
  }
  const serviceScroll = secRef => {
    document.dispatchEvent(new Event("service_Selection"))
  }
  const testimonialScroll = secRef => {
    document.dispatchEvent(new Event("testimonial_Selection"))
  }
  const seoClick = () => {
    seoScroll()
  }
  const ppcClick = () => {
    ppcScroll()
  }
  const wbClick = () => {
    wbScroll()
  }
  const logoClick = () => {
    logoScroll()
  }
  const serviceClick = () => {
    serviceScroll()
  }
  const testimonialClick = () => {
    testimonialScroll()
  }
 
const scrollTop = () =>{
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
});
}
  return (
    <footer>
      <div className="Container">
      <div className="footer-grid">
      <div className='left-grid'>
        <div className='main-footer-heading'>
          <div className='footer-heading'>
            <Link to="" className='brandneed-logo'>
            <img src={LogoFooter} alt="" />
            </Link>
          <p className='first-footer-heading'>The Brandneed</p>
          <div className='heading-divider'></div>
          <p className='second-footer-heading'>Digital Marketing Solutions</p>
          </div>
        </div>
        <p className='brandneed-footer-info'>
          The Brandneed is the one-stop destination for your Brand’s all Marketing needs. Witness your Business, Brand or Organization grow in no time with our Digital marketing services.
          </p>

        <div className='socialemedia-container'>
          <h6 className="socialemedia-head">Follow us on</h6>
        <div className='socialemedia-item'>
                <a href="https://www.facebook.com/Thebrandneed_-100642212749197" target="_blank"rel="noreferrer"><img className='social-logo' src={Facebook} alt="" /></a>
                <a href="https://instagram.com/the.brandneed" target="_blank"rel="noreferrer"><img className='social-logo' src={Instagram} alt="" /></a>
                <a href="https://www.linkedin.com/company/thebrandneed/" target="_blank"rel="noreferrer"><img className='social-logo' src={Linkedin} alt="" /></a>
                {/* <a href="" target="_blank"rel="noreferrer"><img className='social-logo' src={Twitter} alt="" /></a>
                <a href="" target="_blank"rel="noreferrer"><img className='social-logo' src={Youtube} alt="" /></a> */}
            </div>
        </div>
      </div>
      <div className='right-grid'>
        <ul className='menu-grid'>
          <li className='menu-grid-list'>
            <ul>
              <li>
                <h2 className='footer-tran-heading'> Company</h2>
              </li>
              <li className="menu-item">
                <button type='button' onClick={scrollTop}>Home</button>
              </li>
              <li className="menu-item">
                <button type='button' onClick={serviceClick}>Services </button>
              </li>
              <li className="menu-item">
                <button type='button' onClick={testimonialClick}>Testimonials</button>
              </li>
              <li className="menu-item">
                <a type='button' href="/sitemap.xml" target="_blank">Sitemap</a>
              </li>
            </ul>
          </li>
          <li className='menu-grid-list'>
            <ul>
              <li>
                <h2 className='footer-tran-heading'>Services</h2>
              </li>
              <li className="menu-item">
                <button type='button' onClick={seoClick}>SEO</button>
              </li>
              {/* <li className="menu-item">
                <button type='button' onClick={ppcClick}>SMM</button>
              </li> */}
              <li className="menu-item">
                <button type='button' onClick={ppcClick}>PPC</button>
              </li>
              {/* <li className="menu-item">
                <button type='button' onClick={ppcClick}>Email Marketing</button>
              </li>
              <li className="menu-item">
                <button type='button' onClick={ppcClick}>Content Marketing</button>
              </li> */}
              <li className="menu-item">
                <button type='button' onClick={wbClick}>Website Builder</button>
              </li>
              <li className="menu-item">
                <button type='button' onClick={logoClick}>Logo Creation</button>
              </li>
            </ul>
          </li>
          <li className='menu-grid-list'>
            <ul>
              <li>
                <h2 className='footer-tran-heading'>Contact</h2>
              </li>
              <li className="menu-item">
                <i><img src={Image2} alt="" /></i>
                <a href="tel:+918368214889" target="_blank"rel="noreferrer">
                +91-8368214889</a>
              </li>
              <li className="menu-item">
              <i><img src={Image3} alt="" /> </i>
                <a href="mailto:support@edneed.com" target="_blank"rel="noreferrer">support@edneed.com</a>
              </li>
              <li className="menu-item mt-10">
                <i><img src={Image4} alt="" /> </i>
                <p><b>India : </b> 203, Tower C, Ithum Towers, Sector 62, Noida, Uttar Pradesh 201309</p>
              </li>
              <li className="menu-item">
                <i><img src={Image4} alt="" /> </i>
                <p> <b>USA : </b> 9009 North Plaza, Unit 141 Austin Texas 78753</p>
              </li>
            </ul>
          </li>
        </ul>
        <div>
        </div>
      </div>
      </div>
      <div className='powered-by-divider'></div>
      <div className='powered-by-section'>
        <p>Copyright@2024 Edneed. All rights reserved</p>
      </div>
      </div>
    </footer>
  )
}

export default Footer