import React, { useEffect, useRef, useState } from 'react'
import "./Brandneed.scss";
import AnimImage from "../Assets/Images/scrolling-anim-image.png";
import Image1 from '../Assets/Images/partner1.png';
import Image2 from '../Assets/Images/partner2.png'
import Image3 from '../Assets/Images/partner3.png'
import Image4 from '../Assets/Images/partner4.png'
import Image5 from '../Assets/Images/partner5.png'
import Image6 from '../Assets/Images/partner6.png'

// import Image8 from '../Assets/Images/rhombus2.png'
import Image9 from '../Assets/Images/fb5.png'
import Image10 from '../Assets/Images/instagram3.png'
import Image11 from '../Assets/Images/linkedin3.png'
import Image12 from '../Assets/Images/research.png'
import Image13 from '../Assets/Images/optimize.png'
import Image14 from '../Assets/Images/marketing.png'
import Image15 from '../Assets/Images/business.png'
// import Image16 from '../Assets/Images/rhombus3.png'
// import Image17 from '../Assets/Images/rhombus4.png'
import Image19 from '../Assets/Images/meter.png'
import Image20 from '../Assets/Images/meter2.png'
import AdsImage from '../Assets/Images/ads_image.png'
import Image22 from '../Assets/Images/social-group.png'
import Image23 from '../Assets/Images/heart-text.png'
import Image24 from '../Assets/Images/green-text.png'
import WhiteSmoke from '../Assets/Images/whitesmoke.png'
import Image30 from '../Assets/Images/desktop.png'
import Image31 from '../Assets/Images/column.png'
import Image32 from '../Assets/Images/phone.png'
import Image33 from '../Assets/Images/mail2.png'
import Image34 from '../Assets/Images/location2.png'
import Image35 from '../Assets/Images/about.png'
import ValidationUtils from '../common/ValidationUtils';
import { Link } from 'react-router-dom';
import Facebook from "../Assets/Images/social/facebook.png";
import Instagram from "../Assets/Images/social/instagram.png";
import Linkedin from "../Assets/Images/social/linkedin.png";
import Logo1 from "../Assets/Images/sample-logo-1.png";
import Logo2 from "../Assets/Images/sample-logo-2.png";
import Logo3 from "../Assets/Images/sample-logo-3.png";
import Wb_Image from "../Assets/Images/wb_image.png";
import RotateArrow from "../Assets/Images/webbuilderarrow.png";
import AdsImageBg from "../Assets/Images/reachedbg.png";
import CurvedArrow from "../Assets/Images/curved-arrow.png";
import ArtWork1 from "../Assets/Images/hero-artwork1.png";
import ArtWork2 from "../Assets/Images/hero-artwork2.png";
import CrossArrow from "../Assets/Images/cross-arrow.png";
import WbAnim1 from "../Assets/Images/wb-anim1.png";
import WbAnim2 from "../Assets/Images/wb-anim2.png";
import kshitij from "../Assets/Images/kshitij.png"
import AmitRai from "../Assets/Images/testimonial/amit_rai.jpg"
import GokulSaini from "../Assets/Images/testimonial/gokul_saini.png"
import Navprabhat from "../Assets/Images/testimonial/navprabhat.jpg"
import DineshSood from "../Assets/Images/testimonial/dinesh_sood.png"
import Ankur from "../Assets/Images/testimonial/ankur.png"

import Anim1 from "../Assets/Images/animation1.png";
import Anim2 from "../Assets/Images/animation2.png";
import Anim3 from "../Assets/Images/animation3.png";
import Anim4 from "../Assets/Images/animation4.png";
import BoostArt1 from "../Assets/Images/boosting-artwork1.png";
import BoostArt2 from "../Assets/Images/boosting-artwork2.png";
import Cursor from "../Assets/Images/cursor.png";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom"
import DoubleArrowIcon from "../Assets/Images/double-arrow-icon.png";

import axios from 'axios';
const BrandneedContent = () => {
  const [fullName, setFullName] = useState("")
  const [contactNumber, setcontactNumber] = useState("")
  const [email, setEmail] = useState("")
  const [query, setQuery] = useState("")
  const [contactError, setContactError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [queryError, setQueryError] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleChange = (e) => {
    let inputValue = e.target.value;
    let inputName = e.target.name;
    // console.log(inputValue,"val")
    if (inputName === "fullName") {
      setFullName(inputValue)
    }
    if (inputName === "contactNumber") {
      setcontactNumber(inputValue)
      setContactError(ValidationUtils.isEmpty(inputValue))
      setContactError(false)
    }
    if (inputName === "email") {
      setEmail(inputValue)
      setEmailError(false)
    } if (inputName === "query") {
      setQuery(inputValue)
      setQueryError(false)
    }
  }
  const validContactDetails = () => {
    let isValid = true;
    if (contactNumber) {
      if (contactNumber.length < 10) {
        isValid = false;
      }
      else {
        isValid = true;
      }
    }
    else {
      isValid = false;
    }
    return isValid;
  }

  const validEmail = () => {
    let isValid = true
    if (email) {
      if (!ValidationUtils.isEmail(email)) {
        isValid = false
        setEmailError(true)
      } else {
        isValid = true
      }
    } else {
      isValid = true
    }
    return isValid
  }
  let navigate = useNavigate();
  const handleRoute = () => {
    setLoading(false)
    navigate("../thank-you", { replace: true })
  }
  let data = {
    name: fullName,
    contact: contactNumber,
    email: email,
    query: query
  }
  const handleMesage = async () => {
    let contactDetailsValid = validContactDetails();
    setContactError(!contactDetailsValid)
    if (ValidationUtils.isEmpty(contactNumber)) {
      setContactError(true)
    }
    let emailValid = validEmail()
    setEmailError(!emailValid)
    if (ValidationUtils.isEmpty(query)) {
      setQueryError(true)
    }

    // console.log(contactDetailsValid, emailValid, ValidationUtils.isNotEmpty(query)   ,"3 133")
    if (contactDetailsValid && emailValid && ValidationUtils.isNotEmpty(query)) {
      setLoading(true)
      const  Postdata  = await axios.post("https://commonservices.edneed.com/contact/submitrequest", data)
      handleRoute()
      setFullName("")
      setcontactNumber("")
      setEmail("")
      setQuery("")
    }
  }


  const requestDemoRef = useRef(null)
  const requestDemoScroll = () => requestDemoRef.current.scrollIntoView({ behavior: 'smooth' })

  const seoRef = useRef(null)
  const seoScroll = () => seoRef.current.scrollIntoView({ behavior: 'smooth' })

  const socialRef = useRef(null)
  const socialScroll = () => socialRef.current.scrollIntoView({ behavior: 'smooth' })

  const adsRef = useRef(null)
  const adsScroll = () => adsRef.current.scrollIntoView({ behavior: 'smooth' })

  const logosRef = useRef(null)
  const logosScroll = () => logosRef.current.scrollIntoView({ behavior: 'smooth' })


  const getConsultationScroll = () => requestDemoRef.current.scrollIntoView({ behavior: 'smooth' })
  const maximizeNowScroll = () => requestDemoRef.current.scrollIntoView({ behavior: 'smooth' })

  const aboutusRef = useRef(null);
  const serviceRef = useRef(null);
  const testimonialRef = useRef(null);
  useEffect(() => {
    document.addEventListener("aboutus_Selection", () => {
      aboutusRef.current.scrollIntoView({ behavior: 'smooth' })
    })
    document.addEventListener("service_Selection", () => {
      serviceRef.current.scrollIntoView({ behavior: 'smooth' })
    })
    document.addEventListener("testimonial_Selection", () => {
      testimonialRef.current.scrollIntoView({ behavior: 'smooth' })
    })
    document.addEventListener("contact_Selection", () => {
      requestDemoRef.current.scrollIntoView({ behavior: 'smooth' })
    })
    document.addEventListener("seo_Selection", () => {
      seoRef.current.scrollIntoView({ behavior: 'smooth' })
    })
    document.addEventListener("ppc_Selection", () => {
      adsRef.current.scrollIntoView({ behavior: 'smooth' })
    })
    document.addEventListener("wb_Selection", () => {
      logosRef.current.scrollIntoView({ behavior: 'smooth' })
    })
    document.addEventListener("logo_Selection", () => {
      logosRef.current.scrollIntoView({ behavior: 'smooth' })
    })
  }, [])

  return (
    <React.Fragment>
      <div className='contact-links'>
        <div> <p className='follow-us-text-container'>Follow us </p></div>
        <span className='follow-us-divider'></span>
        <div className='folow-us-logo-container'>
          <a href="https://www.facebook.com/Thebrandneed_-100642212749197" target="_blank"rel="noreferrer"><img src={Facebook} alt="" /></a>
          <a href="https://instagram.com/the.brandneed" target="_blank"rel="noreferrer"><img src={Instagram} alt="" /></a>
          <a href="https://www.linkedin.com/company/thebrandneed/" target="_blank"rel="noreferrer"><img src={Linkedin} alt="" /></a>
        </div>
      </div>
      <section className='section-hero'>
        <div className="Container brandneed-container">
          <p className='digital-agency'>Identity Beyond Infinity</p>
          <div className='brandneed-large-text'>
          <p>Take your </p>
            <p>Brand & Make it Grand</p>
            <span className='artwork-1'>
              <img src={ArtWork1} alt="" />
            </span>
            <span className='artwork-2'>
              <img src={ArtWork2} alt="" />
            </span>
          </div>
          <div className='brandneed-top-service-container'>
            <p>Unlock the growth of your Brand’s Digital Presence</p>
            <p>Come forth and unleash the Growth</p>
          </div>
          <button className='request-btn' onClick={requestDemoScroll}>Request Demo <Link to="#call-from-request-button"></Link></button>
          <p className='grow-conatiner'>Organizations growing with The Brandneed</p>
          <div className='partner-container'>
            <div>
              <img src={Image1} alt="" />
            </div>
            <div>
              <img src={Image2} alt="" />
            </div>
            <div>
              <img src={Image3} alt="" />
            </div>
            <div>
              <img src={Image4} alt="" />
            </div>
            <div>
              <img src={Image5} alt="" />
            </div>
            <div>
              <img src={Image6} alt="" />
            </div>
          </div>
        </div>
      </section >


      <section ref={serviceRef}>
        <div className='Container brandneed-container'>

          <div className='brandneed-functionality-container'>
            <div className='left-brandneed-functionality'>
              <div className='functional-section'>
                <div className='shape_background' onClick={seoScroll}>
                  <div className='functionality-image'>
                    <i className='functionality-image-logo1 functionality-icon '></i>
                  </div>
                  <div className='content-of-functionality'>
                    <div className='optimize-content'>
                      <p className='seo-heading'>SEO</p>
                      <p className='seo-heading-info'>Rank higher in google search, experience prolific website traffic.</p>

                    </div>
                  </div>
                </div>
              </div>

              <div className='functional-section'>
                <div className='shape_background' onClick={socialScroll}>
                  <div className='functionality-image'>
                    <i className='functionality-image-logo4 functionality-icon'></i>
                  </div>
                  <div className='content-of-functionality'>
                    <div className='optimize-content'>
                      <p className='seo-heading'>SMM</p>
                      <p className='seo-heading-info'>Social media endorsement. Higher reach & customer engagement.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='functional-section'>
                <div className='shape_background' onClick={adsScroll}>
                  <div className='functionality-image'>
                    <i className='functionality-image-logo3 functionality-icon'></i>
                  </div>
                  <div className='content-of-functionality'>
                    <div className='optimize-content'>
                      <p className='seo-heading'>PPC</p>
                      <p className='seo-heading-info'>Impactful Ad campaigns through our precise keywords.</p>
                    </div>
                  </div>
                </div>
              </div>


              <div className='functional-section'>
                <div className='shape_background' onClick={logosScroll}>
                  <div className='functionality-image'>
                    <i className='functionality-image-logo2 functionality-icon '></i>
                  </div>
                  <div className='content-of-functionality'>
                    <div className='optimize-content'>
                      <p className='seo-heading'>LOGOS</p>
                      <p className='seo-heading-info'>Logo design and customization for impeccable branding & recognition.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='right-brandneed-functionality'>
              <div className='brandnee-ques-container'>
                <p>Our Expertise </p>
              </div>
              <div className='brandneed-online-solution'>
                <p>Unmatched</p>
                <p>Digital Marketing</p>
                <p>Solutions for Online Businesses</p>
              </div>
              <div className='seo-expert'>
                <p>Ignoring Digital Marketing is like opening a business but not telling anyone.</p>
              </div>
              <div className='main-development-container'>
                <div className='development-container'>
                  <i><img src={Image12} alt="" /></i>
                  <p>Research & Analysis</p> </div>
                <div className='development-container'>
                  <i><img src={Image13} alt="" /></i>
                  <p>Optimize your SEO</p> </div>
                <div className='development-container'>
                  <i><img src={Image14} alt="" /></i>
                  <p>Online Marketing</p>
                </div>
                <div className='development-container'>
                  <i><img src={Image15} alt="" /></i>
                  <p>Grow Business</p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </section >


      <section ref={seoRef}>
        <div className='Container brandneed-container'>
          <div className='boosting-container'>
            <div className='cross-arrow-image'>
              <img src={CrossArrow} alt="" />
            </div>
            <div className='boosting-container-left'>
              <p className='boosting-heading'>Boosting</p>
              <div className='boost-conversion-container'>
                <p>Optimum </p>
                <p>Configuration for <span className='boost-conversion-rate-heading'>Maximum </span> Conversion</p>
                {/* <p></p> */}
              </div>
              <div className='change-container'>
                <p>Let’s talk about figures</p>
              </div>
              <div className='performance-increasing-container'>

                <div className='increase-traffic-container'>
                  <p className='increasing-calculation'>80%</p>
                  <div className='increase-content'>
                    <p>Increase in</p>
                    <p>Brand Awareness</p>
                  </div>
                </div>

                <div className='increase-traffic-container-divider'></div>

                <div className='increase-visits-container'>
                  <p className='increasing-calculation'>55%</p>
                  <div className='increase-content'>
                    <p>Increase in</p>
                    <p>Web Traffic</p>
                  </div>
                </div>

                <div className='increase-traffic-container-divider'></div>

                <div className='increase-views-container'>
                  <p className='increasing-calculation'>75%</p>
                  <div className='increase-content'>
                    <p>Increase in</p>
                    <p>Brand Credibility</p>
                  </div>
                </div>
              </div>
              <div>
                <button className='consultation-btn' onClick={getConsultationScroll}>Boost now!</button>
              </div>
            </div>
            <div className='boosting-container-right '>
              <div className='women-image-container'>
                <div className='women-image'>

                  <img className='boost-art-1' src={BoostArt1} alt="" />
                  <img className='boost-art-2' src={BoostArt2} alt="" />
                </div>
                <div className='meter-container up-down'>
                  <div className='grow-business'>
                    <p>Grow your </p>
                    <p> business</p>
                  </div>

                  <div className='meter-image-divider'></div>

                  <img className='meter-image-first' src={Image19} alt="" />
                </div>
                <div className='ads-analytics-container up-down'>
                  <p className='ads-heading'>Ads Analytics</p>
                  <div className='ads-image-container'>
                    <div>
                      <img src={Image20} alt="" />
                    </div>
                    <div className='meter2-image-divider'></div>
                    <div className='meter-2-grow-percentage'>
                      <div className='ads-analytics-arrow'></div>
                      <p>53%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </section>





      <section ref={socialRef}>
        <div className='Container'>
          <div className='social-media-management-container'>
            <div className='curved-arrow-image'>
              <img src={CurvedArrow} alt="" />
            </div>
            <div className='social-media-management-left'>
              <p className='social-management-heading'>Social Media Marketing</p>
              <div className='maximize-visibility-container'>
                <p>Enhance your</p>
                <p className='maximize-heading'>Presence</p>
              </div>
              <div className='social-media-management-article-container'>
                <p>Boost your online visibility</p>
                <p>Unlock growth and endless possibilities.</p>
                <p>Unrivaled Social media marketing strategy by our experts</p>
              </div>
              <div className='orgainc-traffic-conatiner'>
                <div className='orgainc-like-container-first'>
                  <img src={Image23} alt="" />
                  <p>Get organic likes and traffic</p>
                </div>
                <div className='orgainc-like-container-second'>
                  <img src={Image24} alt="" />
                  <p>Profile establishment with top-notch engagement</p>
                </div>
              </div>
              <button className='maximize-button' onClick={maximizeNowScroll}>Increase Visibility</button>
              <div className='rotate-arrow-image'>
                <img src={RotateArrow} alt="" />
              </div>
            </div>
            <div className='social-media-management-right'>
              <img className='smoke-top' src={WhiteSmoke} alt="" />
              <div className='animation-image'>
                {/* <img src={Anim1} alt=""/>
                <img src={Anim2} alt=""/>
                <img src={Anim3} alt=""/>
                <img src={Anim4} alt=""/> */}
                <img id="scroll-element" src={AnimImage} alt="" />
              </div>
              <img className='smoke-bottom' src={WhiteSmoke} alt="" />
            </div>
          </div>

        </div>
      </section >






      <section className='ads-management-section' ref={adsRef}>
        <div className='Container brandneed-container'>
          <div className='ads-management-container'>

            <div className='ads-management-left-container'>
              <div className='ads-image-container'>
                <img className='ads-management-image' src={AdsImage} alt="" />
              </div>
            </div>
            <div className='ads-management-right-conatiner'>
              <p className='ads-management-heading'>Ads Management</p>
              <div className='reach-heading'>
                <p>Reach</p>
                <p className='reimagined'>Beyond Limits <i class="ads-management-arrow-right">
                  <img src={DoubleArrowIcon} alt=""/>
                  </i></p>
              </div>
              <div className='target-audience-container'> <p>Precise audience targeting through carefully curated ads for marketing</p>
                <p> for your business needs</p>
              </div>
              <p className='spend-less-heading'>More Conversion, Less Expenditure</p>
              <div className='social-connect-container'>
                <p className='advertising-platform-heading'>Advertising Platforms</p>
                <img className='social-connect-image' src={Image22} alt="" />
              </div>
            </div>
          </div>

        </div>
      </section>


      {/* logo and website builder container */}

      <section className='web-builder-section' ref={logosRef}>

        <div className='Container'>
          <div className='logo-and-website-builder-main-container'>
            <div className='website-builder-main-container'>
              {/* left side container where you got all text and headig of this container */}
              <div className='left-logo-builder-container' >
                <p className='logo-and-website-heading '>Logos & Website Builder</p>
                <div className='way-change-heading' >
                  <p>Reinvent Your</p>
                  <p><span className='build-heading'>Identity <i className='cursor-icon'><img src={Cursor} alt="" /> </i></span> &nbsp;Exhibit</p>
                  <p>Your Quality</p>
                </div>
                <div className='decades-goes-for-website-builder-container'>
                  <p>#1 Website Building Platform for Businesses, E-Commerce, and Marketing</p>
                </div>
                <div className='website-builder-button'>
                  <a href="https://edneed.com/" target="_blank" rel="noreferrer" >Reinvent Now!</a>
                </div>
                <div className='features-container-website-builder'>
                  <div >
                    <p className='features-container-heading'>60+</p>
                    <p className='features-container-co-heading'>Websites</p>
                    <p className='features-container-co-heading'>Created</p>
                  </div>
                  <div>
                    <p className='features-container-heading'>10+</p>
                    <p className='features-container-co-heading'>Logos</p>
                    <p className='features-container-co-heading'>Designed</p>
                  </div>
                  <div>
                    <p className='features-container-heading'>40+</p>
                    <p className='features-container-co-heading'>Active Clients</p>
                  </div>
                </div>
                <div className='RotateArrow'>
                  <img src={RotateArrow} alt="" />
                </div>
              </div>
              <div className='right-logo-builder-container'>
                <div className='desktop-container'>
                  <div className='web-builder-image-bg'>
                    <img className='image-desktop' src={Wb_Image} alt="" />
                    <div className='anim-overlay'>
                      <img className='up-down' src={WbAnim1} alt="" />
                      <img className='up-down' src={WbAnim2} alt="" />
                    </div>
                  </div>
                  <div className='right-logo-partner-container'>
                    <div className='website-builder-partner-image'>
                      <img className='partner-image-logo' src={Logo1} alt="" />
                    </div >
                    <div className='website-builder-partner-image'>
                      <img className='partner-image-logo' src={Logo2} alt="" />
                    </div>
                    <div className='website-builder-partner-image'>
                      <img className='partner-image-logo' src={Logo3} alt="" />
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>

      {/* testimonials conatiner */}
      <section ref={testimonialRef}>
        <div className='Container brandneed-container'>
          <div className='main-testimonials-container'>
            <div className='column-image-container'>
              <img src={Image31} alt="" />
            </div>
            <div className='testimonials-conatiner-first'>

              <div className='coustmer-review-heading'>
                <p className='testimonials-heading'>Reviews</p>
                <p>See what</p>
                <p>our customers</p>
                <p>say?</p>
              </div>
              <div className='couster-review-image-container'>
                <figure>
                  <img src={Navprabhat} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Nav Prabhat Singh</h5>
                      <div className='divider'></div>
                      <h6>Proprietor, The Lacuna Story</h6>
                      <p>Without a doubt, 5 out of 5 stars. I will definitely recommend The Brandneed to others also.
                        They provide services with a personal touch.
                      </p>
                    </div>
                  </figcaption>
                </figure>
                <figure>
                  <img src={AmitRai} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Amit Rai</h5>
                      <div className='divider'></div>
                      <h6>Director Architect, ANN Associates</h6>
                      <p>Great designers with good knowledge of the work. Would like to recommend others. For us, their services deserve no less than 5 stars.</p>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className='testimonials-conatiner-second'>
              <div className='couster-review-image-container'>
                <figure>
                  <img src={GokulSaini} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Gokul Saini</h5>
                      <div className='divider'></div>
                      <h6>Director, Uva Jagriti Sansthan</h6>
                      <p>Exceptional services, the marketing team know their work. I would definitely recommend it, we will give it 5 out of 5.</p>
                    </div>
                  </figcaption>
                </figure>
                <figure>
                  <img src={DineshSood} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Dinesh Sood</h5>
                      <div className='divider'></div>
                      <h6>Director, Kasauli Exports Surplus</h6>
                      <p>Brandneed’s social media marketing is proving to be a game changer for us. We are 100% content with the services, 5 stars without any doubt.</p>
                    </div>
                  </figcaption>
                </figure>
                <figure>
                  <img src={Ankur} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Ankur khare</h5>
                      <div className='divider'></div>
                      <h6>Director Addon Nextgen Pvt Ltd</h6>
                      <p>Brandneed has an experienced & excellent team. They not only provide services but they also provide ideas as per business requirements.</p>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* form container  */}
      <section ref={requestDemoRef}>
        <div className='main-form-container'>
          <div className='form-background'>
            <div className='Container'>
              <p className='form-background-heading'>Get in Touch</p>
              <div className='form-information'>
                <p>
                  Get advice from our experts, and Boost your online presence <br /> with our specifically designed social media management strategies.
                </p>
              </div>
            </div>
            <div className='artwork-wrap'>
              <span className='artwork-outer'></span>
              <span className='artwork-inner'></span>
            </div>
          </div>
          <div className='Container'>
            <div className='form-container'>
              <div className='form-conatiner-left'>
                <p className='form-container-heading'>Contact Information</p>
                <p className='form-container-contact-info'>Interested in a Full Demo or Furthur Information? Contact us</p>
                <div className='form-contact-number-container'>
                  <img src={Image32} alt="" />
                  <a href="tel:+918368214889" target="_blank"rel="noreferrer">
                +91-8368214889</a>
                </div>
                <div className='form-mail-container'>
                  <img src={Image33} alt="" />
                  <a href="mailto:support@edneed.com" target="_blank"rel="noreferrer">support@edneed.com</a>
                </div>
                <div className='form-location-container'>
                  <img src={Image34} alt="" />
                  <p> Address - 203, Tower C, Ithum Towers, Sector 62, Noida, Uttar Pradesh 201309</p>
                </div>
                <span className='artwork-inner'></span>
              </div>
              <div className='form-conatiner-right'>
                <div className='personal-information-container'>
                  <div className='form-input'>
                    <input className={`input-container`} type="text" placeholder='Full Name' name="fullName" onChange={handleChange} value={fullName} />
                  </div>
                  <div className='form-input'>
                    <input className={`input-container ${(!contactNumber && contactError) && "error"}`} type="tel" placeholder='Phone Number' name="contactNumber" onChange={handleChange} value={contactNumber} />
                    {!contactNumber && contactError &&
                      <small>Phone Number is required</small>
                    }
                    {contactNumber && contactError &&
                      <small>Phone Number is invalid </small>
                    }
                  </div>
                  <div className='form-input'>
                    <input className={`input-container ${(emailError && email) && "error"}`} type="mail" placeholder=' Email id (Optional)' name="email" onChange={handleChange} value={email} />
                    {emailError && email &&
                      <small>Email is invalid</small>
                    }
                  </div>
                  <div className='form-input'>
                    <textarea className={`input-container ${queryError && "error"}`} type="text" placeholder='Enter Your Query' rows="4" name="query" onChange={handleChange} value={query} />

                    {queryError ?
                      <small> Your query is required</small>
                      :
                      <small className='max-text'>500 characters allowed</small>
                    }
                  </div>
                </div>
                {
                  loading ?   <button className='form-send-message-button'>Sending... </button> :  <button className='form-send-message-button' onClick={handleMesage}>Send</button>
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* about us container of brandneed */}
      <section ref={aboutusRef}>
        <div className='Container brandneed-container'>
          <div className='about-us-container'>
            <div className='about-us-conatiner-first'>
              <p className='about-us-heading'>About us </p>
              <p className='about-us-subheading'>Take your Brand to Extraordinary Heights</p>
            </div>
            <div className='about-us-conatiner-second'>

              <div className='about-image-container'><img className='about-us-image' src={Image35} alt="" /></div>
              <div className='about-us-main-content-contaiiner' >
                <p className='about-us-content' >The Brandneed is the ultimate destination for all the requirements that your Brand needs.
                  We, at Brandneed, offer unmatched Digital Marketing Solutions that can instantly boost your brand’s reputation and skyrocket its growth. The need for Digital Marketing in today’s world is inevitable, the competition is tough and the need to shine out from the rest is at an all-time high.
                  The Brandneed is the ideal platform for you, that provides multiple online marketing strategies which can uplift your brand’s value with maximum cost-effectiveness.
                </p>
                <p className='about-us-content'>To know more about us :- Check our website <a href="Edneed.com">Edneed.com</a>  </p>
              </div>
            </div>

          </div>
        </div>
      </section>

    </React.Fragment >
  )
}
export default BrandneedContent