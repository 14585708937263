import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BrandneedHomePage from './brandneed';
import './Assets/Style/Global.scss'
import Thankyou from './brandneed/Thankyou';
import Testimonial from './brandneed/Components/Testimonial';
import Service from './brandneed/Components/Service';
import About from './brandneed/Components/About';
import Contact from './brandneed/Components/Contact';
import PageNotFound from './common/PageNotFound/PageNotFound'
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BrandneedHomePage />}> </Route>
        <Route path="/about" element={<About />}> </Route>
        <Route path="/service" element={<Service />}> </Route>
        <Route path="/testimonial" element={<Testimonial />}> </Route>
        <Route path="/contact" element={<Contact />}> </Route>
        
        <Route path="/thank-you" element={<Thankyou />}> </Route>
        <Route path="/error-404" element={<PageNotFound />}> </Route>
        <Route path="/*" element={<PageNotFound />}> </Route>

        {/* <Route index element={<Home />} /> */}
        {/* <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}

      </Routes>
    </BrowserRouter>
  );
}

export default App;
