import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import ScrollToTop from './scrollToTop'

const Layout = ({children}) => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  return (
    <React.Fragment>
    <Header/>
    {children}
    <Footer/>
    <ScrollToTop/>
    </React.Fragment>
  )
}

export default Layout