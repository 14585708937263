export default class ValidationUtils {
    static isEmpty(value){
        return value == null || value === undefined || !value || value.length === 0 ?
        true : false;
    }

    static isEmail=(email)=>{
        let re = new RegExp(
          /^(([^<>()\][\]\\.,;:\s@"]+(\.[^<>()\][\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        return re.test(String(email).toLowerCase());
      }
      static isNotEmpty(value) {
        return value == null ||
          value === undefined ||
          !value ||
          value.length === 0
          // !value.trim()
          ? false
          : true;
      }
}