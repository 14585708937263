import React from 'react'
import Layout from '../../Layout'

import Image12 from '../../Assets/Images/research.png'
import Image13 from '../../Assets/Images/optimize.png'
import Image14 from '../../Assets/Images/marketing.png'
import Image15 from '../../Assets/Images/business.png'

const Service = () => {
  return (
    <Layout>
 <div className='Container brandneed-container service-page'>

<div className='brandneed-functionality-container'>
  <div className='left-brandneed-functionality'>
    <div className='functional-section'>
      <div className='shape_background'>
        <div className='functionality-image'>
          <i className='functionality-image-logo1 functionality-icon '></i>
        </div>
        <div className='content-of-functionality'>
          <div className='optimize-content'>
            <p className='seo-heading'>SEO</p>
            <p className='seo-heading-info'>Rank higher in google search, experience prolific website traffic.</p>

          </div>
        </div>
      </div>
    </div>

    <div className='functional-section'>
      <div className='shape_background'>
        <div className='functionality-image'>
          <i className='functionality-image-logo4 functionality-icon'></i>
        </div>
        <div className='content-of-functionality'>
          <div className='optimize-content'>
            <p className='seo-heading'>SMM</p>
            <p className='seo-heading-info'>Social media endorsement. Higher reach & customer engagement.</p>
          </div>
        </div>
      </div>
    </div>

    <div className='functional-section'>
      <div className='shape_background'>
        <div className='functionality-image'>
          <i className='functionality-image-logo3 functionality-icon'></i>
        </div>
        <div className='content-of-functionality'>
          <div className='optimize-content'>
            <p className='seo-heading'>PPC</p>
            <p className='seo-heading-info'>Impactful Ad campaigns through our precise keywords.</p>
          </div>
        </div>
      </div>
    </div>


    <div className='functional-section'>
      <div className='shape_background'>
        <div className='functionality-image'>
          <i className='functionality-image-logo2 functionality-icon '></i>
        </div>
        <div className='content-of-functionality'>
          <div className='optimize-content'>
            <p className='seo-heading'>LOGOS</p>
            <p className='seo-heading-info'>Logo design and customization for impeccable branding & recognition.</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div className='right-brandneed-functionality'>
    <div className='brandnee-ques-container'>
      <p>Our Expertise </p>
    </div>
    <div className='brandneed-online-solution'>
      <p>Unmatched</p>
      <p>Digital Marketing</p>
      <p>Solutions for Online Businesses</p>
    </div>
    <div className='seo-expert'>
      <p>Ignoring Digital Marketing is like opening a business but not telling anyone.</p>
    </div>
    <div className='main-development-container'>
      <div className='development-container'>
        <i><img src={Image12} alt="" /></i>
        <p>Research & Analysis</p> </div>
      <div className='development-container'>
        <i><img src={Image13} alt="" /></i>
        <p>Optimize your SEO</p> </div>
      <div className='development-container'>
        <i><img src={Image14} alt="" /></i>
        <p>Online Marketing</p>
      </div>
      <div className='development-container'>
        <i><img src={Image15} alt="" /></i>
        <p>Grow Business</p>
      </div>
    </div>
  </div>
</div>

</div>
    </Layout>
  )
}

export default Service