import React from 'react'
import Layout from '../../Layout'
import AmitRai from "../../Assets/Images/testimonial/amit_rai.jpg"
import GokulSaini from "../../Assets/Images/testimonial/gokul_saini.png"
import Navprabhat from "../../Assets/Images/testimonial/navprabhat.jpg"
import DineshSood from "../../Assets/Images/testimonial/dinesh_sood.png"
import Ankur from "../../Assets/Images/testimonial/ankur.png"
import Image31 from '../../Assets/Images/column.png'

const Testimonial = () => {
  return (
    <Layout>
          <div className='Container brandneed-container  testimonial-page'>
          <div className='main-testimonials-container'>
            <div className='column-image-container'>
              <img src={Image31} alt="" />
            </div>
            <div className='testimonials-conatiner-first'>

              <div className='coustmer-review-heading'>
                <p className='testimonials-heading'>Reviews</p>
                <p>See what</p>
                <p>our customers</p>
                <p>say?</p>
              </div>
              <div className='couster-review-image-container'>
                <figure>
                  <img src={Navprabhat} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Nav Prabhat Singh</h5>
                      <div className='divider'></div>
                      <h6>Proprietor, The Lacuna Story</h6>
                      <p>Without a doubt, 5 out of 5 stars. I will definitely recommend The Brandneed to others also.
                        They provide services with a personal touch.
                      </p>
                    </div>
                  </figcaption>
                </figure>
                <figure>
                  <img src={AmitRai} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Amit Rai</h5>
                      <div className='divider'></div>
                      <h6>Director Architect, ANN Associates</h6>
                      <p>Great designers with good knowledge of the work. Would like to recommend others. For us, their services deserve no less than 5 stars.</p>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className='testimonials-conatiner-second'>
              <div className='couster-review-image-container'>
                <figure>
                  <img src={GokulSaini} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Gokul Saini</h5>
                      <div className='divider'></div>
                      <h6>Director, Uva Jagriti Sansthan</h6>
                      <p>Exceptional services, the marketing team know their work. I would definitely recommend it, we will give it 5 out of 5.</p>
                    </div>
                  </figcaption>
                </figure>
                <figure>
                  <img src={DineshSood} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Dinesh Sood</h5>
                      <div className='divider'></div>
                      <h6>Director, Kasauli Exports Surplus</h6>
                      <p>Brandneed’s social media marketing is proving to be a game changer for us. We are 100% content with the services, 5 stars without any doubt.</p>
                    </div>
                  </figcaption>
                </figure>
                <figure>
                  <img src={Ankur} alt="" />
                  <figcaption>
                    <div className='figcaption-inner'>
                      <h5>Ankur khare</h5>
                      <div className='divider'></div>
                      <h6>Director Addon Nextgen Pvt Ltd</h6>
                      <p>Brandneed has an experienced & excellent team. They not only provide services but they also provide ideas as per business requirements.</p>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
    </Layout>
  )
}

export default Testimonial