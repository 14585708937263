import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../Layout'

import ValidationUtils from '../../common/ValidationUtils';
import Image31 from '../../Assets/Images/column.png'
import Image32 from '../../Assets/Images/phone.png'
import Image33 from '../../Assets/Images/mail2.png'
import Image34 from '../../Assets/Images/location2.png'
import Image35 from '../../Assets/Images/about.png'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
const Contact = () => {
    const [fullName, setFullName] = useState("")
    const [contactNumber, setcontactNumber] = useState("")
    const [email, setEmail] = useState("")
    const [query, setQuery] = useState("")
    const [contactError, setContactError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [queryError, setQueryError] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleChange = (e) => {
      let inputValue = e.target.value;
      let inputName = e.target.name;
      // console.log(inputValue,"val")
      if (inputName === "fullName") {
        setFullName(inputValue)
      }
      if (inputName === "contactNumber") {
        setcontactNumber(inputValue)
        setContactError(ValidationUtils.isEmpty(inputValue))
        setContactError(false)
      }
      if (inputName === "email") {
        setEmail(inputValue)
        setEmailError(false)
      } if (inputName === "query") {
        setQuery(inputValue)
        setQueryError(false)
      }
    }
    const validContactDetails = () => {
      let isValid = true;
      if (contactNumber) {
        if (contactNumber.length < 10) {
          isValid = false;
        }
        else {
          isValid = true;
        }
      }
      else {
        isValid = false;
      }
      return isValid;
    }
  
    const validEmail = () => {
      let isValid = true
      if (email) {
        if (!ValidationUtils.isEmail(email)) {
          isValid = false
          setEmailError(true)
        } else {
          isValid = true
        }
      } else {
        isValid = true
      }
      return isValid
    }
    let navigate = useNavigate();
    const handleRoute = () => {
      setLoading(false)
      navigate("../thank-you", { replace: true })
    }
    let data = {
      name: fullName,
      contact: contactNumber,
      email: email,
      query: query
    }
    const handleMesage = async () => {
      let contactDetailsValid = validContactDetails();
      setContactError(!contactDetailsValid)
      if (ValidationUtils.isEmpty(contactNumber)) {
        setContactError(true)
      }
      let emailValid = validEmail()
      setEmailError(!emailValid)
      if (ValidationUtils.isEmpty(query)) {
        setQueryError(true)
      }
  
      // console.log(contactDetailsValid, emailValid, ValidationUtils.isNotEmpty(query)   ,"3 133")
      if (contactDetailsValid && emailValid && ValidationUtils.isNotEmpty(query)) {
        setLoading(true)
        const  Postdata  = await axios.post("https://commonservices.edneed.com/contact/submitrequest", data)
        handleRoute()
        setFullName("")
        setcontactNumber("")
        setEmail("")
        setQuery("")
      }
    }
  
  
    const requestDemoRef = useRef(null)
    const requestDemoScroll = () => requestDemoRef.current.scrollIntoView({ behavior: 'smooth' })
  
    const seoRef = useRef(null)
    const seoScroll = () => seoRef.current.scrollIntoView({ behavior: 'smooth' })
  
    const socialRef = useRef(null)
    const socialScroll = () => socialRef.current.scrollIntoView({ behavior: 'smooth' })
  
    const adsRef = useRef(null)
    const adsScroll = () => adsRef.current.scrollIntoView({ behavior: 'smooth' })
  
    const logosRef = useRef(null)
    const logosScroll = () => logosRef.current.scrollIntoView({ behavior: 'smooth' })
  
  
    const getConsultationScroll = () => requestDemoRef.current.scrollIntoView({ behavior: 'smooth' })
    const maximizeNowScroll = () => requestDemoRef.current.scrollIntoView({ behavior: 'smooth' })
  
    const aboutusRef = useRef(null);
    const serviceRef = useRef(null);
    const testimonialRef = useRef(null);
    useEffect(() => {
      document.addEventListener("aboutus_Selection", () => {
        aboutusRef.current.scrollIntoView({ behavior: 'smooth' })
      })
      document.addEventListener("service_Selection", () => {
        serviceRef.current.scrollIntoView({ behavior: 'smooth' })
      })
      document.addEventListener("testimonial_Selection", () => {
        testimonialRef.current.scrollIntoView({ behavior: 'smooth' })
      })
      document.addEventListener("contact_Selection", () => {
        requestDemoRef.current.scrollIntoView({ behavior: 'smooth' })
      })
      document.addEventListener("seo_Selection", () => {
        seoRef.current.scrollIntoView({ behavior: 'smooth' })
      })
      document.addEventListener("ppc_Selection", () => {
        adsRef.current.scrollIntoView({ behavior: 'smooth' })
      })
      document.addEventListener("wb_Selection", () => {
        logosRef.current.scrollIntoView({ behavior: 'smooth' })
      })
      document.addEventListener("logo_Selection", () => {
        logosRef.current.scrollIntoView({ behavior: 'smooth' })
      })
    }, [])
  
  return (
    <Layout>
        <div className='main-form-container  contact-page'>
          <div className='form-background'>
            <div className='Container'>
              <p className='form-background-heading'>Get in Touch</p>
              <div className='form-information'>
                <p>
                  Get advice from our experts, and Boost your online presence <br /> with our specifically designed social media management strategies.
                </p>
              </div>
            </div>
            <div className='artwork-wrap'>
              <span className='artwork-outer'></span>
              <span className='artwork-inner'></span>
            </div>
          </div>
          <div className='Container'>
            <div className='form-container'>
              <div className='form-conatiner-left'>
                <p className='form-container-heading'>Contact Information</p>
                <p className='form-container-contact-info'>Interested in a Full Demo or Furthur Information? Contact us</p>
                <div className='form-contact-number-container'>
                  <img src={Image32} alt="" />
                  <a href="tel:+918368214889" target="_blank"rel="noreferrer">
                +91-8368214889</a>
                </div>
                <div className='form-mail-container'>
                  <img src={Image33} alt="" />
                  <a href="mailto:support@edneed.com" target="_blank"rel="noreferrer">support@edneed.com</a>
                </div>
                <div className='form-location-container'>
                  <img src={Image34} alt="" />
                  <p> India - 203, Tower C, Ithum Towers, Sector 62, Noida, Uttar Pradesh 201309</p>
                </div>
                <div className='form-location-container'>
                  <img src={Image34} alt="" />
                  <p> USA - 9009 North Plaza, Unit 141 Austin Texas 78753</p>
                </div>
                <span className='artwork-inner'></span>
              </div>
              <div className='form-conatiner-right'>
                <div className='personal-information-container'>
                  <div className='form-input'>
                    <input className={`input-container`} type="text" placeholder='Full Name' name="fullName" onChange={handleChange} value={fullName} />
                  </div>
                  <div className='form-input'>
                    <input className={`input-container ${(!contactNumber && contactError) && "error"}`} type="tel" placeholder='Phone Number' name="contactNumber" onChange={handleChange} value={contactNumber} />
                    {!contactNumber && contactError &&
                      <small>Phone Number is required</small>
                    }
                    {contactNumber && contactError &&
                      <small>Phone Number is invalid </small>
                    }
                  </div>
                  <div className='form-input'>
                    <input className={`input-container ${(emailError && email) && "error"}`} type="mail" placeholder=' Email id (Optional)' name="email" onChange={handleChange} value={email} />
                    {emailError && email &&
                      <small>Email is invalid</small>
                    }
                  </div>
                  <div className='form-input'>
                    <textarea className={`input-container ${queryError && "error"}`} type="text" placeholder='Enter Your Query' rows="4" name="query" onChange={handleChange} value={query} />

                    {queryError ?
                      <small> Your query is required</small>
                      :
                      <small className='max-text'>500 characters allowed</small>
                    }
                  </div>
                </div>
                {
                  loading ?   <button className='form-send-message-button'>Sending... </button> :  <button className='form-send-message-button' onClick={handleMesage}>Send</button>
                }
              </div>
            </div>
          </div>
        </div>
    </Layout>
  )
}

export default Contact